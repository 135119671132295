<template>
  <form v-if="steps == 3" @submit.prevent="handleSubmit()">
    <div class="row">
      <div class="form-group w-100">
        <label for="">Insured Type</label
        ><select
          v-model="data.insuredType"
          class="form-control"
          id="insurancetype"
          disabled
        >
          <option value="">Select Insured Type</option>
          <option value="Individual">Individual</option>
          <option value="Corporate">Corporate</option>
        </select>
      </div>

      <div class="row" v-if="data.insuredType == 'Individual'">
        <div class="form-group col-md-2">
          <label for="">Title</label
          ><select
            v-model="data.title"
            class="form-control"
            id="title"
            disabled
          >
            <option value="">Select Title</option>
            <option value="Mr.">Mr</option>
            <option value="Mrs.">Mrs</option>
            <option value="Miss.">Miss</option>
            <option value="Dr.">Dr</option>
            <option value="Egnr.">Egnr</option>
            <option value="Others">Others</option>
          </select>
        </div>

        <div class="form-group col-md-5">
          <label for="">First Name</label
          ><input
            class="form-control"
            id="firstname"
            type="text"
            v-model="data.firstName"
            placeholder="Enter First Name"
            disabled
          />
        </div>
        <div class="form-group col-md-5">
          <label for="">Last Name</label
          ><input
            class="form-control"
            id="lastname"
            type="text"
            v-model="data.lastName"
            placeholder="Enter Last Name"
            disabled
          />
        </div>

        <div class="form-group col-md-2">
          <label for="">Gender</label
          ><select
            v-model="data.gender"
            class="form-control"
            id="title"
            disabled
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div class="form-group col-md-5">
          <label for="">Phone Number</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.phoneNo"
            placeholder="Enter Phone Number"
            disabled
          />
        </div>

        <div class="form-group col-md-5">
          <label for="">Email Address</label
          ><input
            class="form-control"
            id="email"
            type="email"
            v-model="data.email"
            placeholder="Enter Email"
            disabled
          />
        </div>

        <div class="form-group col-md-2">
          <label for="">State Of Residence</label
          ><input
            v-model="data.stateOfResidence"
            class="form-control"
            id="title"
            disabled
          />
        </div>

        <div class="form-group col-md-2">
          <label for="">LGA</label
          ><input disabled v-model="data.lga" class="form-control" id="title" />
        </div>

        <div class="form-group col-md-8">
          <label for="">Address</label
          ><input
            class="form-control"
            id="address"
            type="text"
            v-model="data.address"
            placeholder="Enter Address"
            disabled
          />
        </div>
        <div class="form-group col-md-4">
          <label for="">Date Of Birth</label
          ><input
            class="form-control"
            id="dateofbirth"
            type="date"
            v-model="data.dateOfBirth"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Occupation</label>
          <input v-model="data.occupation" disabled class="form-control" />
        </div>

        <div class="form-group col-md-4">
          <label for="">Marketing Staff</label
          ><input v-model="data.mktStaff" disabled class="form-control" />
        </div>

        <div class="form-group col-md-4">
          <label for="">Means Of Identification</label
          ><select
            v-model="data.meansOfId"
            class="form-control"
            id="meansofid"
            disabled
          >
            <option value="">Select Means Of Identification</option>
            <option value="Passport">Passport</option>
            <option value="NIN">NIN</option>
            <option value="Drivers License">Drivers License</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Identification Number</label
          ><input
            class="form-control"
            id="idnumber"
            type="text"
            v-model="data.idNumber"
            placeholder="Enter ID Number"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">BVN</label
          ><input
            class="form-control"
            id="bvn"
            type="text"
            v-model="data.bvn"
            placeholder="Enter BVN"
            disabled
          />
        </div>
      </div>

      <div class="row" v-if="data.insuredType == 'Corporate'">
        <div class="form-group col-md-4">
          <label for="">Director's Title</label
          ><select
            v-model="data.directorTitle"
            class="form-control"
            id="title"
            disabled
          >
            <option value="">Select Title</option>
            <option value="Mr.">Mr</option>
            <option value="Mrs.">Mrs</option>
            <option value="Miss.">Miss</option>
            <option value="Dr.">Dr</option>
            <option value="Egnr.">Egnr</option>
            <option value="Others">Others</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's First Name</label
          ><input
            class="form-control"
            id="firstname"
            type="text"
            v-model="data.directorFirstname"
            placeholder="Enter First Name"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Last Name</label
          ><input
            class="form-control"
            id="lastname"
            type="text"
            v-model="data.directorSurname"
            placeholder="Enter Last Name"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Other Name</label
          ><input
            class="form-control"
            id="othername"
            type="text"
            v-model="data.directorOthername"
            placeholder="Enter Other Name"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Gender</label
          ><select
            v-model="data.gender"
            class="form-control"
            id="title"
            disabled
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Phone Number</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.directorPhoneNumber"
            placeholder="Enter Phone Number"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Email Address</label
          ><input
            class="form-control"
            id="email"
            type="email"
            v-model="data.directorEmail"
            placeholder="Enter Email"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Date Of Birth</label
          ><input
            class="form-control"
            id="dateofbirth"
            type="date"
            v-model="data.directorDateOfBirth"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Means Of Identification</label
          ><select
            v-model="data.directorMeansOfId"
            class="form-control"
            id="meansofid"
            disabled
          >
            <option value="">Director's Means Of Identification</option>
            <option value="Passport">Passport</option>
            <option value="NIN">NIN</option>
            <option value="Drivers License">Drivers License</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Identification Number</label
          ><input
            class="form-control"
            id="idnumber"
            type="text"
            v-model="data.directorIdNumber"
            placeholder="Enter ID Number"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company Phone Number</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.phoneNo"
            placeholder="Enter Phone Number"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company Landline</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.landLine"
            disabled
            placeholder="Enter Landline Number"
          />
        </div>

        <div class="form-group col-md-12">
          <label for="">Company Name</label
          ><input
            class="form-control"
            id="companyName"
            type="text"
            v-model="data.firstName"
            placeholder="Enter Company Name"
            disabled
          />
        </div>

        <div class="form-group col-md-2">
          <label for="">Company LGA</label
          ><input disabled v-model="data.lga" class="form-control" id="title" />
        </div>

        <div class="form-group col-md-10">
          <label for="">Company Address</label
          ><input
            class="form-control"
            id="address"
            type="text"
            v-model="data.address"
            placeholder="Enter Address"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company's Date Of Incorporation</label
          ><input
            class="form-control"
            id="dateofbirth"
            type="date"
            v-model="data.dateOfBirth"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company Partner Name</label
          ><input
            class="form-control"
            id="companyPartnerName"
            type="text"
            v-model="data.companyPartnerName"
            placeholder="Company Partner Name"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company RC Number</label
          ><input
            class="form-control"
            id="tin"
            type="text"
            v-model="data.rcNumber"
            placeholder="Enter RC Number"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company TIN</label
          ><input
            class="form-control"
            id="tin"
            type="text"
            v-model="data.tin"
            placeholder="Enter TIN"
            disabled
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company Line Of Business</label>
          <input v-model="data.occupation" disabled class="form-control" />
        </div>

        <div class="form-group col-md-4">
          <label for="">Marketing Staff</label
          ><input v-model="data.mktStaff" disabled class="form-control" />
        </div>
      </div>

      <div class="form-group col-md-4">
        <label for="">Policy Start Date</label
        ><input
          class="form-control"
          id="startdate"
          type="date"
          v-model="data.startDate"
          @change="addDays(data.startDate, data.duration)"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Policy Duration (In Days)</label
        ><input
          class="form-control"
          id="duration"
          type="number"
          v-model="data.duration"
          placeholder="Enter Duration"
          @change="addDays(data.startDate, data.duration)"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Policy End Date</label
        ><input
          class="form-control"
          id="duration"
          type="text"
          v-model="metaData.policyEnd"
          placeholder="Policy End Date"
          disabled
        />
      </div>

      <div class="form-group col-md-3">
        <label for="">TIN</label
        ><input
          class="form-control"
          id="tin"
          type="text"
          v-model="data.tin"
          placeholder="Enter TIN"
          disabled
        />
      </div>

      <!-- <div class="form-group col-md-3">
        <label for="">Endorsement Option</label
        ><select
          v-model="data.endorsementOptions"
          class="form-control"
          id="endorsementoption"
          disabled
        >
          <option value="">Select Endorsement Option</option>
          <option
            v-for="option in endorsementOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div> -->

      <div class="form-group col-md-3">
        <label for="">Voyage Type</label
        ><select
          @change="getRate()"
          v-model="data.voyageType"
          class="form-control"
          id="voyagetype"
          disabled
        >
          <option value="">Select Voyage Type</option>
          <option
            v-for="voyage in voyageTypes"
            :key="voyage.id"
            :value="voyage.name"
          >
            {{ voyage.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="">Parking Type</label
        ><select
          v-model="data.parkingType"
          class="form-control"
          id="parkingtype"
          disabled
        >
          <option value="">Select Parking Type</option>
          <option
            v-for="parkingType in parkingTypes"
            :key="parkingType.id"
            :value="parkingType.name"
          >
            {{ parkingType.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="">Conveyance</label
        ><select
          v-model="data.conveyance"
          class="form-control"
          id="conveyance"
          disabled
        >
          <option value="">Select Conveyance</option>
          <option
            v-for="conveyance in conveyances"
            :key="conveyance.id"
            :value="conveyance.name"
          >
            {{ conveyance.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-4">
        <label for="">Cover Scope</label
        ><select
          v-model="data.coverScope"
          class="form-control"
          id="coverscope"
          disabled
        >
          <option value="">Select Cover Scope</option>
          <option
            v-for="scope in coverScopes"
            :key="scope.id"
            :value="scope.id"
          >
            {{ scope.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-4">
        <label for="">Certificate Type</label
        ><select
          v-model="data.certificateType"
          class="form-control"
          id="certificatetype"
          disabled
        >
          <option value="">Select Certificate Type</option>
          <option
            v-for="certificate in certificateTypes"
            :key="certificate"
            :value="certificate"
          >
            {{ certificate }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-4">
        <label for="">Nature Of Cargo</label
        ><select
          v-model="data.natureofCargo"
          class="form-control"
          id="natureofcargo"
          disabled
        >
          <option value="">Select Nature Of Cargo</option>
          <option
            v-for="cargo in natureOfCargos"
            :key="cargo.id"
            :value="cargo.name"
          >
            {{ cargo.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-4">
        <label for="">Proformal Invoice Number</label
        ><input
          class="form-control"
          id="proformalinvoice"
          type="text"
          v-model="data.proformaInvoiceNo"
          placeholder="Enter Proformal Invoice Number"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Vessel</label
        ><input
          class="form-control"
          id="vessel"
          type="text"
          v-model="data.vessel"
          placeholder="Enter Vessel"
          disabled
        />
      </div>

      <div v-if="data.coverScope === 'MCI'" class="form-group col-md-4">
        <label for="">Type Of Cover</label
        ><input
          v-model="data.typeofCover"
          class="form-control"
          id="typeofCover"
          disabled
        />
      </div>

      <div class="form-group col-md-6">
        <label for="">Shipment Going From</label
        ><input
          class="form-control"
          id="fromaddress"
          type="text"
          v-model="data.from"
          placeholder="Enter From Address"
          disabled
        />
      </div>

      <div class="form-group col-md-6">
        <label for="">Shipment Going To</label
        ><input
          class="form-control"
          id="toaddress"
          type="text"
          v-model="data.to"
          placeholder="Enter To Address"
          disabled
        />
      </div>

      <div v-if="data.coverScope === 'MOC'" class="form-group col-md-12">
        <label for="">Selected Cover Types</label>
        <div class="row mx-2">
          <div
            v-for="type in data.addClauseOpenCover"
            :key="type"
            class="form-check my-2 col-md-3"
          >
            <input
              class="form-check-input"
              type="checkbox"
              v-model="data.addClauseOpenCover"
              :value="type"
              :id="type"
              disabled
            />
            <label class="form-check-label" :for="type">
              {{ type }}
            </label>
          </div>
        </div>
      </div>

      <div v-if="data.addClause.length != 0" class="form-group col-md-12">
        <label for="">Additional Clauses</label>
        <div
          v-for="clause in data.addClause"
          :key="clause"
          class="form-check my-2"
        >
          <input
            class="form-check-input"
            type="checkbox"
            checked
            :id="clause"
            disabled
          />
          <label class="form-check-label" :for="clause">
            {{ clause }}
          </label>
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="">Cargo Description (Detailed)</label
        ><textarea
          class="form-control"
          v-model="data.cargoDescription"
          id="cargodescription"
          rows="3"
          disabled
        ></textarea>
      </div>

      <div class="form-group col-md-4">
        <label for="">Currency</label
        ><input
          v-model="data.exCurrency"
          class="form-control"
          id="currency"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Exchange Rate</label
        ><input
          class="form-control"
          id="exrate"
          type="number"
          v-model="data.exRate"
          placeholder=""
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Invoiced Value</label
        ><input
          class="form-control"
          id="invoicedValue"
          type="text"
          v-model="data.invoicedValue"
          placeholder="Enter Invoiced Value"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Naira Value</label
        ><input
          class="form-control"
          id="nairavalue"
          type="text"
          :value="metaData.nairaValue.toLocaleString()"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Sum Insured</label
        ><input
          class="form-control"
          id="suminsured"
          type="text"
          :value="data.sumInsured.toLocaleString()"
          placeholder="Enter Sum Insured"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Rate</label
        ><input
          class="form-control"
          id="premiumrate"
          type="number"
          :value="data.premiumRate"
          placeholder="Enter Premium Rate"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Premium</label
        ><input
          class="form-control"
          id="premium"
          type="text"
          :value="data.premium.toLocaleString()"
          placeholder="Enter Premium"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Loading (+ %)</label
        ><input
          class="form-control"
          id="excess"
          type="text"
          :value="metaData.percentLoading.toLocaleString()"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Excess</label
        ><input
          class="form-control"
          id="excess"
          type="text"
          v-model="data.excess"
          placeholder="Enter Excess"
          disabled
        />
      </div>

      <!-- <div class="form-group col-md-12 mx-3 form-check">
        <input
          class="form-check-input"
          type="checkbox"
          :value="true"
          v-model="makePayment"
          id="makePayment"
        />
        <label class="form-check-label" for="makePayment">
          Make Payment Now
        </label>
      </div> -->
    </div>

    <div class="my-3">
      <button type="" @click.prevent="goBack()" class="btn btn-primary">
        Back
      </button>

      <button v-if="loading" class="btn btn-primary float-right disabled">
        <div
          class="spinner-border spinner-border-sm mx-1 text-light"
          role="status"
        ></div>
        Loading...
      </button>
      <button
        v-else-if="!metaData.paid"
        @click.prevent="flwPay()"
        type="button"
        class="btn btn-primary float-right"
      >
        Make Payment
      </button>
      <button v-else type="submit" class="btn btn-primary float-right">
        Book Marine Insurance
      </button>
    </div>
  </form>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "pinia";
import { useMarineStore } from "@/stores/marine";
import { useAdminStore } from "@/stores/admin";
import { useMainStore } from "@/stores/main";
import $config from "../../../../helpers/config";
import { $Session } from "../../../../helpers/storage";
export default {
  name: "Buy Marine",
  data() {
    return {
      metaData: { paid: false },
      data: {
        tranrsactionreference: "",
      },
    };
  },
  computed: {
    ...mapState(useMainStore, ["user"]),
    ...mapState(useMarineStore, [
      "loading",
      "states",
      "componentLoading",
      "lgas",
      "coverScopes",
      "endorsementOptions",
      "certificateTypes",
      "marketers",
      "currencyRates",
      "steps",
      "marineData",
      "marineMetaData",
    ]),
    ...mapState(useAdminStore, [
      "coverTypes",
      "natureOfCargos",
      "conveyances",
      "voyageTypes",
      "parkingTypes",
    ]),
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getStates",
      "getLga",
      "getCoverScopes",
      "getEndorsementOptions",
      "getCertificateTypes",
      "getMarketers",
      "getCurrency",
      "incrementStep",
      "saveStep",
      "buyMarine",
    ]),
    ...mapActions(useAdminStore, [
      "getAllConveyances",
      "getAllNatureOfCargos",
      "getAllCoverTypes",
      "getAllParkingTypes",
      "getAllVoyageTypes",
    ]),

    goBack() {
      this.incrementStep(2);
      this.$router.push(`/customer/buymarine/step-2`);
    },

    async flwPay() {
      $Session.setItem("data", JSON.stringify(this.data));
      $Session.setItem("metadata", JSON.stringify(this.metaData));
      this.data.tranrsactionreference = `CHIMARINETXR-${Math.floor(
        Math.random() * 100000000
      )}`;

      //eslint-disable-next-line no-undef
      const modal = FlutterwaveCheckout({
        public_key: $config.flwLivePublicKey,
        tx_ref: this.data.tranrsactionreference,
        amount: this.data.premium,
        currency: "NGN",
        payment_options: "card, banktransfer, ussd",
        onclose: () => {
          modal.close();
        },

        callback: () => {
          this.metaData.paid = true;
          $Session.setItem("data", JSON.stringify(this.data));
          $Session.setItem("metadata", JSON.stringify(this.metaData));
        },
        meta: {
          agent_id: this.user.brokerId,
        },
        customer: {
          email: this.user.email,
          name: this.user.broker,
        },
        customizations: {
          title: "Consolidated Hallmark Insurance",
          description: "Payment for marine insurance",
          logo: "https://s3-symbol-logo.tradingview.com/consolidated-hallmark-insurance-plc--600.png",
        },
      });
    },

    async handleSubmit() {
      await this.sendMarineRequest();
      $Session.removeItem("data");
      $Session.removeItem("metadata");
    },

    async sendMarineRequest() {
      const res = await this.buyMarine(this.data);
      if (res.isSuccessful) {
        Swal.fire({
          icon: "success",
          title: "Success",
          confirmButtonText: "View Policy",
          reverseButtons: true,
          text: "Marine Insurance Policy Booked Successfully",
          confirmButtonColor: "#990100",
        }).then((result) => {
          if (result.isConfirmed) {
            this.incrementStep(1);
            this.saveStep({});
            this.$router.push(`/customer/viewBusiness/${res.certificateNo}`);
          }
        });
      }
    },
  },

  created() {
    if ($Session.hasItem("data")) {
      this.incrementStep(3);
      this.data = JSON.parse($Session.getItem("data"));
    }
    if ($Session.hasItem("metadata")) {
      this.metaData = JSON.parse($Session.getItem("metadata"));
    }
    if (this.steps < 3) {
      this.$router.push(`/agent/buymarine/step-${this.steps}`);
    }

    this.data = { ...this.data, ...this.marineData };
    this.metaData = { ...this.metaData, ...this.marineMetaData };
  },
};
</script>
